import React from 'react';
import { Link } from 'react-router-dom';  // Importación añadida para que funcione el Link
import './css/Footer.css';
import logo from '../../images/Logos/LogoHeader.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import ButtonHablemos from '../Button/ButtonHablemos';


const Footer = () => {
  return (
    <div className="footer">
      <div className="logo-container-footer">
        <img src={logo} alt="Imagen Logo" className='logo-footer' />
        {/*<Link className='buttonFooter' to={`/anfitrion`}>PANEL ANFITRION</Link>*/}

      </div> 
      <div className='column-footer-description'> 
          <p className='p1'>ALFONSO RONDANELLI M</p> 
          <p className='p2'>Ingeniero Comercial, Colegio de Ingenieros de Chile A.G. N° 4379-5. <br /> Certificación de Inversión, Asociación de Fondos Mutuos de Chile A.G, Universidad Adolfo Ibáñez.</p>

      </div>
      <div className="column-footer-rows">
        <p className="contact-title">Contacto</p> 
        <p className="contact-info">Antonio Varas 303, Oficina 612, Providencia, Santiago de Chile</p>
        <p className="contact-info">+569 9433 70 91</p>
        <p className="contact-info"></p>
        <p className="contact-info">alfonso@segurosrondanelli.cl</p>
        <ButtonHablemos />
      
      </div> 

      {/* Botón de WhatsApp sticky */}
      <a
        href="https://wa.me/56994337091?text=Hola,%20me%20gustaría%20saber%20más%20de%20Seguros%20Rondanelli" 
        target="_blank" 
        rel="noopener noreferrer"
        className='fixed bottom-10 right-10 w-16 h-16 ButtonWhatsapp text-white rounded-full shadow-lg flex items-center justify-center transition-colors duration-300 hover:bg-green-600'
        aria-label='Chat with us on WhatsApp'
      >
        <FontAwesomeIcon icon={faWhatsapp} className='text-4xl' />
      </a>

    </div>
  );
};

export default Footer;

//